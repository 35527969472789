import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';
import 'swiper/css/a11y';
const { _x } = wp.i18n;
// init Swiper:
new Swiper('.news-slider__carousel', {
  loop: false,
  slidesPerView: 'auto',
  watchOverflow: true,

  // Navigation arrows
  navigation: {
    nextEl: '.news-slider__carousel__navigation .swiper-button-next',
    prevEl: '.news-slider__carousel__navigation .swiper-button-prev',
  },
  a11y: {
    prevSlideMessage: _x('Previous slide', 'swiper accessibility', 'vtx'),
    nextSlideMessage: _x('Next slide', 'swiper accessibility', 'vtx'),
    slideLabelMessage:
      _x('Slide ', 'swiper accessibility', 'vtx') +
      '{{index}}' +
      _x(' of ', 'swiper accessibility', 'vtx') +
      '{{slidesLength}}',
  },
  keyboard: {
    enabled: true,
  },
});
